"use client";
import { Accordion } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import footer from "../../../public/content/footer.json";
import Newsletter from "../Cards/Newsletter";
import InfoModal from "../Modal";

interface FooterProps {
    backgroundColor?: string;
    textColorTitle?: string;
    textColorLink?: string;
    googleLogo?: string;
    facebookLogo?: string;
    appleLogo?: string;
    buttonColor?: string;
    buttonTextColor?: string;
    srcIconAccordion?: string;
    windowsLogo?: string;
}
export default function Footer({
    backgroundColor = "bg-darkGray",
    textColorTitle = "text-white",
    textColorLink = "text-stone-300",
    googleLogo = "/Icons/Footer/GoogleWhite.svg",
    facebookLogo = "/Icons/Footer/FacebookWhite.svg",
    appleLogo = "/Icons/Footer/AppleWhite.svg",
    buttonColor = "bg-white",
    buttonTextColor = "text-black",
    srcIconAccordion = "/Icons/Header/dropdownWhite.svg",
    windowsLogo = "/Icons/Footer/windowsWhite.svg",
}: FooterProps) {
    const year = new Date().getFullYear();
    const router = useRouter();
    const [openModal, setOpenModal] = useState(false);
    return (
        <div className={`${backgroundColor}`}>
            <div className="lg:container mx-auto">
                <div className="w-full h-full  lg:px-20 pt-20 pb-10  flex-col justify-start items-start gap-10 inline-flex ">
                    <div className="w-full px-6  justify-start items-start gap-8  lg:inline-flex hidden">
                        {footer.footerNavigation.slice(0, 5).map((item) => (
                            <>
                                <div
                                    className="w-60 flex-col justify-start items-start gap-2 inline-flex"
                                    key={item.title}
                                >
                                    <div
                                        className={twMerge(
                                            "text-2xl font-bold leading-loose",
                                            textColorTitle,
                                        )}
                                    >
                                        {item.title}
                                    </div>
                                    {item.links.map((link) => (
                                        <div
                                            key={link.name}
                                            className={twMerge(
                                                "text-xl1 font-normal leading-8.5",
                                                textColorLink,
                                            )}
                                        >
                                            {link.url ? (
                                                <Link href={link.url}>{link.name}</Link>
                                            ) : (
                                                <> {link.name}</>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="w-full px-6  justify-start items-start gap-8 lg:inline-flex  ">
                        <div className="hidden lg:block">
                            {footer.footerNavigation.slice(5, 10).map((item) => (
                                <div
                                    key={item.title}
                                    className="w-60 flex-col justify-start items-start gap-2 inline-flex"
                                >
                                    <div
                                        className={twMerge(
                                            "text-2xl font-bold leading-loose",
                                            textColorTitle,
                                        )}
                                    >
                                        {item.title}
                                    </div>

                                    {item.links.map((link) => (
                                        <div
                                            key={link.name}
                                            className={twMerge(
                                                "text-xl1 font-normal leading-8.5",
                                                textColorLink,
                                            )}
                                        >
                                            {link.url ? (
                                                <>
                                                    <Link href={link.url}>
                                                        {link.name}
                                                    </Link>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex gap-2">
                                                        {link.name === "Switzerland" && (
                                                            <Image
                                                                alt=""
                                                                width={20}
                                                                height={20}
                                                                src="/Icons/Footer/Switzerland.svg"
                                                            />
                                                        )}{" "}
                                                        {link.name}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    {item.title === "Bedingungen" && (
                                        <button
                                            type="button"
                                            className={twMerge(
                                                "text-left text-xl1 font-normal leading-8.5",
                                                textColorLink,
                                            )}
                                            data-cc="show-preferencesModal"
                                        >
                                            Cookie-Einstellungen verwalten
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="lg:hidden inline-flex w-full bg-darkGray -ml-4">
                            <Accordion
                                className={twMerge("w-full", backgroundColor)}
                                variant="filled"
                                chevron={
                                    <Image
                                        alt=""
                                        height={20}
                                        width={20}
                                        src={srcIconAccordion}
                                        className="h-6 w-6"
                                    />
                                }
                                chevronPosition="right"
                            >
                                {footer.footerNavigation.slice(0, 5).map((item) => (
                                    <Accordion.Item value={item.title} key={item.title}>
                                        <Accordion.Control
                                            className={`${backgroundColor}`}
                                        >
                                            <p
                                                className={twMerge(
                                                    "text-xl font-semibold leading-loose",
                                                    textColorTitle,
                                                )}
                                            >
                                                {item.title}
                                            </p>
                                        </Accordion.Control>
                                        <Accordion.Panel
                                            className={`${backgroundColor}`}
                                        >
                                            {item.links.map((link) => (
                                                <>
                                                    {link.url ? (
                                                        <Link
                                                            href={link.url}
                                                            key={link.name}
                                                        >
                                                            <div
                                                                className={twMerge(
                                                                    "text-lg font-normal leading-8.5",
                                                                    textColorTitle,
                                                                )}
                                                            >
                                                                {link.name}
                                                            </div>
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className={twMerge(
                                                                    "text-lg font-normal leading-8.5",
                                                                    textColorTitle,
                                                                )}
                                                            >
                                                                {link.name}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                                {footer.footerNavigation.slice(6, 7).map((item) => (
                                    <Accordion.Item value={item.title} key={item.title}>
                                        <Accordion.Control
                                            className={`${backgroundColor}`}
                                        >
                                            <p
                                                className={twMerge(
                                                    "text-xl font-semibold leading-loose",
                                                    textColorTitle,
                                                )}
                                            >
                                                {item.title}
                                            </p>
                                        </Accordion.Control>
                                        <Accordion.Panel
                                            className={`${backgroundColor}`}
                                        >
                                            {item.links.map((link) => (
                                                <>
                                                    {link.url ? (
                                                        <Link
                                                            href={link.url}
                                                            key={link.name}
                                                        >
                                                            <div
                                                                className={twMerge(
                                                                    "text-lg font-normal leading-8.5",
                                                                    textColorTitle,
                                                                )}
                                                            >
                                                                {link.name}
                                                            </div>
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className={twMerge(
                                                                    "text-lg font-normal leading-8.5",
                                                                    textColorTitle,
                                                                )}
                                                            >
                                                                {link.name}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                            {item.title === "Bedingungen" && (
                                                <button
                                                    type="button"
                                                    className={twMerge(
                                                        "text-lg font-normal leading-8.5",
                                                        textColorTitle,
                                                    )}
                                                    data-cc="show-preferencesModal"
                                                >
                                                    Cookie-Einstellungen verwalten
                                                </button>
                                            )}
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>

                        <div className="lg:w-[450px] lg:pt-2 flex-col justify-start items-start gap-6 inline-flex">
                            <div className="self-stretch h-43 flex-col justify-start items-start gap-2 flex">
                                <div
                                    className={twMerge(
                                        "self-stretch text-xl  lg:text-2xl  font-bold leading-loose",
                                        textColorTitle,
                                    )}
                                >
                                    Support
                                </div>
                                <div
                                    className={twMerge(
                                        "self-stretch text-xl lg:text-xl1 font-normal leading-7 lg:leading-8.5",
                                        textColorLink,
                                    )}
                                >
                                    Du musst nicht mit seelischen Verletzungen und
                                    Blockaden leben. Eine Fernbehandlung kann genau da
                                    helfen.
                                </div>
                                <button
                                    onClick={() => {
                                        router.push("/de/bestellen");
                                    }}
                                    type="button"
                                    className={twMerge(
                                        "w-44 h-10 px-3 py-2 rounded-lg justify-start items-center gap-1 inline-flex",
                                        buttonColor,
                                    )}
                                >
                                    <div className="w-6 h-6 justify-center items-center flex">
                                        <Image
                                            alt=""
                                            height={20}
                                            width={20}
                                            className="w-6 h-6 relative flex-col justify-start items-start flex"
                                            src="/Icons/favorite.svg"
                                        />
                                    </div>
                                    <div
                                        className={twMerge(
                                            "text-center text-opacity-70 text-sm font-normal leading-normal",
                                            buttonTextColor,
                                        )}
                                    >
                                        Looking for help?
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="grow shrink mt-6 lg:pt-2 lg:mt-0 basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div className="h-33 pb-1 flex-col justify-start items-start gap-2 hidden md:flex lg:flex">
                                <div
                                    className={twMerge(
                                        "self-stretch lg:text-2xl font-bold leading-loose",
                                        textColorTitle,
                                    )}
                                >
                                    Zahlungsmethoden
                                </div>
                                <div className="flex space-x-2 lg:block lg:space-y-2 lg:space-x-0">
                                    <div className="justify-start items-center gap-2 inline-flex ">
                                        <Image
                                            alt=""
                                            width={200}
                                            height={50}
                                            src="/images/cardsFooterfirstLine.png"
                                        />
                                    </div>
                                    <div className="justify-start items-center gap-2 inline-flex">
                                        <Image
                                            alt=""
                                            width={110}
                                            height={50}
                                            src="/images/cardsFootersecondLine.png"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="h-33 pb-1 flex-col justify-start items-start gap-2 flex md:hidden lg:hidden ">
                                <div className="self-stretch text-white text-xl font-bold leading-loose">
                                    Zahlungsmethoden
                                </div>
                                <div className="flex space-x-2 lg:block lg:space-y-2 lg:space-x-0">
                                    <Image
                                        alt=""
                                        height={50}
                                        width={300}
                                        src="/images/cardsFooterMobile.png"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch h-0 border border-neutral-700 lg:block hidden" />
                            <div className="self-stretch h-20 lg:flex-col justify-between lg:justify-start items-start lg:gap-2 flex">
                                <div
                                    className={twMerge(
                                        "self-stretch  text-2xl font-bold leading-loose",
                                        textColorLink,
                                    )}
                                >
                                    Rico’s News
                                </div>
                                <button
                                    type="button"
                                    className="px-3 py-2 rounded-lg border border-zinc-500 justify-start items-center gap-1 inline-flex cursor-pointer"
                                    onClick={() => {
                                        setOpenModal(true);
                                    }}
                                >
                                    <div className="text-center text-zinc-500 text-base font-normal leading-normal">
                                        Abonnieren
                                    </div>
                                </button>
                            </div>
                            <InfoModal
                                onClose={() => {
                                    setOpenModal(false);
                                }}
                                opened={openModal}
                                size="lg"
                            >
                                <Newsletter
                                    newsletterForm={true}
                                    buttonPaddings="py-4 px-10"
                                    backgroundColor="bg-white"
                                    title="Rico’s News abonnieren"
                                    hasCheckbox={false}
                                    hasDescription={false}
                                    hasForm={false}
                                    hasTextArea={false}
                                    hasInput={true}
                                    hasSubText={false}
                                    paddings=""
                                    description=""
                                />
                            </InfoModal>
                            <div className="lg:hidden block">
                                <div className="w-60 flex-col justify-start items-start gap-2 inline-flex">
                                    <div
                                        className={twMerge(
                                            "text-xl font-bold leading-loose",
                                            textColorTitle,
                                        )}
                                    >
                                        {footer.footerNavigation[5].title}
                                    </div>
                                    {footer.footerNavigation[5].links.map((link) => (
                                        <div
                                            key={link.name}
                                            className={twMerge(
                                                "text-lg font-normal leading-7",
                                                textColorTitle,
                                            )}
                                        >
                                            <div className="flex gap-2">
                                                {link.name === "Switzerland" && (
                                                    <Image
                                                        alt=""
                                                        height={20}
                                                        width={20}
                                                        src="/Icons/Footer/Switzerland.svg"
                                                    />
                                                )}{" "}
                                                {link.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-2 mx-5 -mt-4">
                        <Image alt="" height={30} width={30} src={googleLogo} />
                        <Image alt="" height={30} width={30} src={facebookLogo} />
                        <Image alt="" height={30} width={30} src={appleLogo} />
                        <Image alt="" height={30} width={30} src={windowsLogo} />
                    </div>
                    <div className="self-stretch justify-start items-center gap-1 inline-flex mx-5">
                        <div
                            className={twMerge(
                                "grow shrink basis-0  text-base font-normal leading-tight",
                                textColorLink,
                            )}
                        >
                            © 1998-{year} - Rico Brunner
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
